<template>
  <div>
    <div style="display: flex">
      <el-button type="primary" @click="add(null)">创建</el-button>
    </div>
    <el-table
      :data="list"
      border
      :max-height="maxHeight"
      style="margin-top: 20px"
    >
      <el-table-column prop="skuId" label="商品ID"></el-table-column>
      <el-table-column prop="skuName" label="商品名称"></el-table-column>
      <!-- <el-table-column prop="startTime" label="开始时间">
        <template slot-scope="scope">
          {{ formatTime(scope.row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="endTime" label="结束时间">
        <template slot-scope="scope">
          {{ formatTime(scope.row.endTime) }}
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="detail(scope.row)"
            >详情</el-button>
          <el-button size="mini" type="text" @click="add(scope.row)"
            >编辑</el-button>
          <!-- <el-button size="mini" type="text" @click="showGiveDialog(scope.row)"
            >赠送</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="totalRows"
      :current-page="page"
      :page-size="20"
      style="margin-top: 20px"
      @current-change="handleChangePage"
    >
    </el-pagination>
    <el-dialog
      title="输入授权的手机号"
      :visible="dialogVisible"
      @close="dialogVisible = false"
      width="30%"
    >
      <el-input v-model="orderSource" placeholder="输入订单来源"></el-input>
      <el-input
        v-model="inputPhone"
        placeholder="输入手机号，多个手机号请换行输入"
        type="textarea"
        rows="4"
        style="margin-top: 10px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmGive">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CourseApi from "../../request/CourseApi";
import dayjs from "dayjs";
export default {
  data() {
    return {
      maxHeight: document.documentElement.clientHeight - 250,
      list: [],
      page: 1,
      totalRows: 0,
      dialogVisible: false,
      currentCourse: null,
      inputPhone: "",
      orderSource: "",
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      CourseApi.list({
        pageNo: this.page,
        pageSize: 20,
        type: 'POINTS'
      }).then((res) => {
        if (res.success) {
          this.list = res.data;
          this.totalRows = res.totalRows;
        }
      });
    },
    handleChangePage(val) {
      this.page = val;
      this.loadData();
    },
    detail(val) {
      this.$router.push({
        path: "/exchange/goods/detail",
        query: {
          skuId: val.skuId,
        },
      });
    },
    add(val) {
      if (val) {
        this.$router.push({
          path: "/exchange/goods/add",
          query: {
            skuId: val.skuId,
          },
        });
      } else {
        this.$router.push("/exchange/goods/add");
      }
    },
    showGiveDialog(val) {
      this.dialogVisible = true;
      this.currentCourse = val;
    },
    confirmGive() {
      if (!this.inputPhone.length) {
        this.$message('手机号不能为空')
        return
      }
      var arr = this.inputPhone.split("\n");
      CourseApi.authorizeUserCourse({
        origin: this.orderSource,
        phoneList: arr,
        skuId: this.currentCourse.skuId,
      }).then(res => {
        if (res.success) {
          this.$message.success('授权成功')
          this.inputPhone = ""
          this.orderSource = ""
          this.dialogVisible = false
        }
      });
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style></style>
